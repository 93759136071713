<template>
  <page >
    <div class="pt-8">
      <div class=" bg-white rounded p-6 m-auto" style="width: 300px">
        <div class=" mt-3 mb-6">
          <div class="text-xl font-medium">{{ type == 1 ? '商户注册' : '推广员注册'}}</div>
          <div class="text-xs text-gray-light mt-2 leading-4">为了保障账户安全，请使用手机号码进行注册</div>
        </div>
        <cube-input v-model="phone" placeholder="手机号" class="mb-4"></cube-input>
        
        <div class="mb-4 flex items-center">
          <cube-input v-model="code" class="flex-1" placeholder="验证码"></cube-input>
          <btn size="md" class="ml-2" outline>获取验证码</btn>
        </div>
        <cube-input v-model="password" placeholder="密码" class="mb-4"></cube-input>
        <cube-input v-model="repassword" placeholder="确认密码" class="mb-4"></cube-input>
        <cube-input v-model="realName" :placeholder="type == 1 ? '商户名称':'真实姓名'" class="mb-4"></cube-input>
        <div class="mb-4" v-if="type == 1">
          <div class="flex justify-center" v-if="photo">
            <img :src="photo" alt="" class="w-48">
          </div>
          <div class="flex flex-col items-center p-4 relative bg-gray-200 rounded" v-else>
            <icon icon="icon-camera" size="40"></icon>
            <div class="mt-3 text-sm">上传营业执照</div>
            <input type="file" class=" absolute inset-0 opacity-0" @change="upload" accept="image/*" />
          </div>
        </div>
        <btn block text-size="base">{{type == 1 ? '商户':'业务员'}}注册</btn>

      </div>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      phone: '',
      code: '',
      realName: '',
      password: '',
      repassword: '',
      type: 1,
      photo:''
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    upload(e){
      console.log(e.target.files[0]);

      this.photo = this.TestData.slides[0].image
    }
  },
  created(){
    this.type = this.$route.params.typeId
  }
}
</script>

<style lang='stylus'>

</style>